import React from "react";
import styled from "styled-components";
import Layout from "@components/Layouts/MainLayout";
import Background from "@images/services/creative_content_background.jpeg";
import { StaticImage } from "gatsby-plugin-image";
import { Container,  Col, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
//import { motion } from "framer-motion";
//import SubForm from "@components/Forms/Sub";

const creativeContent = () => {
  return (
    <Layout>
      <HeroContainer className="main-banner">
        <Container className="d-flex justify-content-center flex-column">
          {/* <motion.div
            animate={{ y: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1.3, delay: 1 }}
          >
            <div className="main-title">
              <h1 className="display-4 fw-bolder">
                Strategic Projects
              </h1>
            </div>
          </motion.div>
          <motion.div
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 1.3, delay: 2 }}
          >
            <div className="main-title btn-container">
              <Button className="animated-btn">Launch Your Campaign</Button>
            </div>
          </motion.div> */}
        </Container>
        <img src={Background} alt="" className="hero-img" />
      </HeroContainer>

      <MaContainer>
        <div className="mt display-3">
          <h1>
            <span class="r">
            Craft strategies to better target communities:
            </span>{" "}
            <br />
            Build greater awareness, campaigns, market research & more.
          </h1>
        </div>

        <SplitSection background="" className="section2 gy-3">
          <Col md={6}>
            <Fade left>
              <div className="content p-md-5">
                <p className="sup display-2">
                  <Heading>
                  Leverage our grassroots  expertise to create content that
                  maximizes your local connection across the areas you care about.
                  </Heading>
                </p>
              </div>
            </Fade>
          </Col>
          <Col className="image" md={6}>
            <Fade right>
              <img
                src="../../images/services/sic.png"
                alt="Hand holding mics"
              />
            </Fade>
          </Col>
        </SplitSection>
      </MaContainer>

      <div className="bg-dark">
        <HContainer>
          <p className="font-sans-serif">
            Our <span class="f">award-winning</span> approach combines :
          </p>
          <div className="font-serif">
            <p class="one"> Community Engagement </p>
            <p class="two"> Media Creation</p>
            <p class="third">And Grassroots Strategy</p>
          </div>
          <p className="font-sans-serif">
            To make your desired stories resonate across channels to engage {" "}
            <span class="b">the right audiences</span>.
          </p>
        </HContainer>
      </div>

      <MaContainer>
        <SplitSection background="" className="section2 gy-3">
          <Col md={{ span: 6, order: "last" }}>
            <Fade left>
              <div className="content p-md-5">
                <p className="sup">
                  <Heading>
                    {" "}
                    Our creative content team works with you to plan and produce all angles of the story you want to capture. {" "}
                  </Heading>
                </p>
              </div>
            </Fade>
          </Col>
          <Col className="image" md={6}>
            <Fade right>
              <StaticImage
                src="../../images/services/k.jpg"
                alt="Hand holding camera"
              />
            </Fade>
          </Col>
        </SplitSection>

        <SplitSection background="" className="section2 gy-3">
          <Col md={6}>
            <Fade left>
              <div className="content p-md-5">
                <p className="sup">
                  <Heading>
                    {" "}
                    No matter if it’s on-the-ground or in-the-air, we have the tools to craft your dynamic narrative.{" "}
                  </Heading>
                </p>
              </div>
            </Fade>
          </Col>
          <Col className="image" md={6}>
            <Fade right>
              <StaticImage
                src="../../images/services/g.jpg"
                alt="Drone Flying"
              />
            </Fade>
          </Col>
        </SplitSection>

        <SplitSection background="" className="section2 gy-3">
          <Col md={{ span: 6, order: "last" }}>
            <Fade left>
              <div className="content p-md-5">
                <p className="sup">
                  <Heading>
                    {" "}
                    We offer end-to-end fulfillment:
                    Whether you need
                     on-demand support to reach 
                     your goals, increase distribution, or grow your audience, we got you covered.  {" "}
                  </Heading>
                </p>
              </div>
            </Fade>
          </Col>
          <Col className="image" md={6}>
            <Fade right>
              <StaticImage
                src="../../images/services/stephen.jpeg"
                alt="Quality Score"
              />
            </Fade>
          </Col>
        </SplitSection>
      </MaContainer>

      {/* <SubForm /> */}

      <Container className="mt-3">
        <small>
        Creative Content Strategy Services are separate from the Vngle newsroom. Clients and brands working with Vngle’s Creative Content Strategy Services do not influence the editorial discretion of our news reporting in any form. Conversely, custom storytelling projects through our Creative Content Strategy Services do permit the collaborative input of the clients and brands we work with.
        </small>
      </Container>
    </Layout>
  );
};

const HeroContainer = styled.div`
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;

  h1 {
    font-family: "Playfair Display", serif;
    margin-bottom: 0rem;
    text-align: center;
  }
  .main-title {
    max-width: 35rem;
    justify-content: center;
    display: flex;
  }
  .btn-container {
    padding: 2rem;
  }

  .animated-btn {
    color: white;
    padding: 15px 30px;
    border-radius: 30px;
    font-weight: bold;
  }

  .hero-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0.9;
  }
`;

const Heading = styled.h1`
  font-family: "Inter", sans-serif;
  font-weight: ${(props) => props.weight || "bold"};
  font-size: clamp(3rem, 7vw, ${(props) => props.size || "2.6rem"});
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: ${(props) => props.lineHeight};
  color: ${(props) => props.color};
`;

const MaContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: center;

  .mt {
    margin-bottom: 4rem;

    .r {
      color: #e612d0;
    }
  }

  .gatsby-image-wrapper {
    height: 100%;

    img {
      border-radius: 10px;
    }
  }

  h1 {
    font-weight: bold;
    font-family: "Playfair Display", serif;
    text-align: center;
    font-size: 3rem;
  }
`;

const HContainer = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 1rem;

  p {
    color: white;
    font-weight: bold;
    font-size: 3rem;
    text-align: left;

    .b {
      color: #1ee4f0;
      text-align: center;
    }

    .f {
      color: var(--bs-primary);
    }
  }

  .one {
    color: #1ee4f0;
    text-align: center;
    animation: flip 3s infinite;
    animation-delay: calc(0.3s * var(--i));
    animation-iteration-count: 5;
  }

  .two {
    color: #1ee4f0;
    text-align: center;
    animation: flip 3s infinite;
    animation-delay: calc(0.3s * var(--i));
    animation-iteration-count: 5;
  }

  .third {
    color: #1ee4f0;
    text-align: center;
    animation: flip 3s infinite;
    animation-delay: calc(0.3s * var(--i));
    animation-iteration-count: 5;
  }

  @keyframes flip {
    0%,
    80% {
      transform: rotateY(360deg);
    }
  }
`;

const SplitSection = styled(Row)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .content {
    background: ${(props) => props.background};
    padding: 2rem;
    border-radius: 10px;
    height: 100%;
    transition: transform 1s;
  }

  @media (max-width: 47rem) {
    &.section2 {
      flex-direction: column-reverse;
    }
  }
`;

export default creativeContent;
