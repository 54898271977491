import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
} from "react-bootstrap";

import styled from "styled-components";
//import Special from "../images/ona/special_offer.png";
import Layout from "../components/Layouts/MainLayout";
import Seo from "../components/Seo";

// import SubFormWrapper from "../components/Forms/Sub";


//import ElectionCoverage from "@images/election_coverage.gif";

import Community from "@images/INI_Community.png";
// import Vngle_INI from "@images/Vngle-CIE_INI_Think_Tank_Imagery.gif";
import Vngle_INI from "@images/Vngle-CIE-INI-Think-Tank-Imagery.mp4";
//import INI_sky from "@images/INI_sky.png";
//import INI_heart from "@images/INI_heart.png";
import INI_Globe from "@images/INI_Globe.png";

import { Fade } from "react-reveal";
import { StaticImage } from "gatsby-plugin-image";


const Calendly = () => {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);

    // Cleanup on component unmount
    return () => {
      head.removeChild(script);
    };
  }, []);

  return (
    <div id="schedule_form">
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/vngle/30minute_partner_informational?hide_event_type_details=1&hide_gdpr_banner=1"
        style={{ minWidth: "320px", height: "700px" }}
      />
    </div>
  );
};

const INI = () => {
  //     const [timeRemaining, setTimeRemaining] = useState('');

  //   useEffect(() => {
  //     const electionDate = new Date("November 5, 2024 00:00:00").getTime();
  //     const countdownFunction = setInterval(() => {
  //       const now = new Date().getTime();
  //       const distance = electionDate - now;

  //       const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //       const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //       const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //       const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //       if (distance > 0) {
  //         setTimeRemaining(
  //             <div style={{ display: 'flex', fontSize: 'calc(1vw + 15px)' }}>
  //                 <CountdownWrapper>Election Countdown:</CountdownWrapper>
  //                 <Time>
  //                     {days}d {hours}h {minutes}m {seconds}s
  //                 </Time>
  //             </div>
  //         );
  //       } else {
  //         clearInterval(countdownFunction);
  //         setTimeRemaining('The election has Hello!');
  //       }
  //     }, 1000);

  //     return () => clearInterval(countdownFunction);
  //   }, []);
  return (
    <Layout>
      <Seo
        title="A Grassroots News Agency"
        description="Vngle is a decentralized grassroots news network covering pressing underrepresented verified coverage on cities across America. Our coverage is for the people, by the people."
      />
      <section
        style={{
          position: "relative",
          width: "auto",
          height: "85vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          marginBottom: "-100px",
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "80%",
            objectFit: "cover",
            zIndex: -1,
          }}
        >
          <source src={Vngle_INI} type="video/mp4" />
        </video>
        <div className="text-white">
          <HeroContainer className="main-banner text-center">
            <Fade bottom>
              <h1 className="display-2 fw-bolder">
                <span>Vngle-CIE:</span>
                <br />
                Institute for Nonpartisan Innovation (INI)
              </h1>
            </Fade>

            <p className="font-sans-serif h5 fw-bold">
              <span>&mdash;</span> Creating Common Ground & Bridging Divides: Advancing Information Innovation In Our Interconnected World.
             <span>&mdash;</span>
            </p>
            {/* <img src={Vngle_INI} alt="" className="hero-img" /> */}
          </HeroContainer>
        </div>
      </section>

      <Container>
        <MainPageContainer>
          <SplitSection
            background="#12161D"
            className="gy-3"
            style={{ color: "#FFFFFF" }}
          >
            <Col className="image" md={6}>
              <Fade left>
                <StaticImage
                  src="../images/VngleCIE.png"
                  alt="City with networks on top"
                />
              </Fade>
            </Col>
            <Col md={6}>
              <Fade right>
                <div className="content">
                  <p className="sup lh-base fs-3">
                    INI is a research, tech, and policy think tank powered by the
                    Vngle and the City University of New York’s (CUNY) Inclusive
                    Economy Initiative (CIE). It is founded to unite
                    universities, industry leaders, and grassroots experts to
                    help address critical challenges that divide communities
                    globally.
                  </p>
                </div>
              </Fade>
            </Col>
          </SplitSection>
        </MainPageContainer>
      </Container>

      <ResponsiveContainer
        fluid
        style={{
          background:
            "linear-gradient(to right, rgba(239, 41, 41), rgba(81, 78, 200))",
          height: "100.964px",
          width: "88%",
          display: "flex",
          borderRadius: "15px",
          justifyContent: "space-between", // Adjust spacing for alignment
          alignItems: "center",
          padding: "0 50px", // Add padding for spacing
          color: "#FFFFFF", // White text color
          fontSize: "18px", // Font size to match
          lineHeight: "1.5", // Adjust line height
        }}
      >
        <div className="fs-4" style={{ flex: "1", fontStyle: "italic" }}>
          Get involved in NYC’s newest think tank!
        </div>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <a href="https://bit.ly/3X5NG3J" target="_blank">
            <StyledButton>Sign Up</StyledButton>
          </a>
        </Row>
      </ResponsiveContainer>

      <Container>
        <Title className="my-5 display-3 font-sans-serif text-center fw-bolder">
          INI’s 3 Pillars:
        </Title>
        <Subtitle className="font-sans-serif h3 text-center">
          “We are building cross-industry coalitions to transform how
          information is shared, understood, and leveraged—because more informed
          communities are productive communities.”
        </Subtitle>
        <MainPageContainer>
          <SplitSection background="var(--bs-primary)" className="gy-3">
            <Col className="image" md={6}>
              <Fade left>
                <StaticImage
                  src="../images/AcrossAmerica.png"
                  alt="City with networks on top"
                />
              </Fade>
            </Col>
            <Col md={6}>
              <Fade right>
                <div className="content p-md-5">
                  <Heading className="">Nonpartisan Representation</Heading>
                  <p className="sup fs-3">
                    Exploring ways to <u>expand how topics are covered from all
                    perspectives and across all regions</u> to create more holistic
                    ways to cover critical topics, overcome bias, and address
                    gaps in information.
                  </p>
                </div>
              </Fade>
            </Col>
          </SplitSection>

          <SplitSection background="#3cf" className="section2 gy-3">
            <Col md={6}>
              <Fade left>
                <div className="content p-md-5">
                  <Heading className="fs-9">Information Provenance</Heading>
                  <p className="sup fs-2 pt-4 fw-semibold">
                    Pioneering innovative ways <u>to support the clarity of news
                    and information across online and in-person mediums</u> by
                    pinpointing the origin of how information is captured.{" "}
                  </p>
                </div>
              </Fade>
            </Col>
            <Col className="image" md={6}>
              <Fade right>
                <StaticImage
                  className="rounded"
                  src="../images/INI_sky.png"
                  alt="Hand holding mics"
                  style={{ width: "100%" }}
                />
              </Fade>
            </Col>
          </SplitSection>

          <SplitSection background="#ff52db" className="section2 gy-3">
            <Col md={{ span: 6, order: "last" }}>
              <Fade right>
                <div className="content p-md-5">
                  <Heading>Responsible Technology & Governance</Heading>
                  <p className="sup fs-3">
                    <u>Developing human-centered uses for various technologies</u>,
                    that ensure communities can use them equitably and
                    accountability is instilled to protect the public.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col className="image" md={6}>
              <Fade left>
                <StaticImage
                  src="../images/INI_heart.png"
                  alt="Workshop participants group photo"
                />
              </Fade>
            </Col>
          </SplitSection>
        </MainPageContainer>
      </Container>

      <TopFeatureContainer>
        <Title className="display-3 fw-bold">The Mission of INI:</Title>
        <Subtitle className="fs-2">
          "To help build common understanding across key topics and create
          infrastructure that helps bring new levels of verified information to
          all."
        </Subtitle>
      </TopFeatureContainer>
      <Image fluid src={INI_Globe} className="pb-5" />

      <TopFeatureContainer>
        <Title className="display-3 fw-bold">Our Vision Of Impact:</Title>
        <Subtitle className="fs-3 fw-semibold">
          Through bringing together Vngle&apos;s decentralized grassroots
          intelligence network and CUNY&apos;s decentralized university system
          of 25 colleges across all 5 NYC boroughs, we seek to further develop 
          actionable strategies to advance
          common understanding of societal challenges that affect all.
          <br/><br /> 
          <strong>Through combining Vngle&apos;s national reporting expertise, CUNY&apos;s
          strong research infrastructure, and the global citizenry of NYC, we
          aim for INI to serve as a common place for elevating critical
          discussion, that&apos;s fueled by the media capital of the world.</strong>
        </Subtitle>
      </TopFeatureContainer>

      <ResponsiveContainer
        fluid
        style={{
          background:
            "linear-gradient(to right, rgba(239, 41, 41), rgba(81, 78, 200))",
          height: "100.964px",
          width: "88%",
          display: "flex",
          borderRadius: "15px",
          justifyContent: "space-between", // Adjust spacing for alignment
          alignItems: "center",
          padding: "0 50px", // Add padding for spacing
          color: "#FFFFFF", // White text color
          fontSize: "18px", // Font size to match
          lineHeight: "1.5", // Adjust line height
        }}
      >
        <div className="fs-2" style={{ flex: "1", fontStyle: "italic" }}>
          Follow INI on LinkedIn!
        </div>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <a href="https://www.linkedin.com/showcase/vnglecie" target="_blank">
            <StyledButton>Follow</StyledButton>
          </a>
        </Row>
      </ResponsiveContainer>

      <HeroContainer className="main-banner">
        <Fade bottom>
          <Title className="display-4 fw-bolder">
            What to expect from INI: 
          </Title>
        </Fade>

        <ul>
          <li>
            <h1>
              <strong>
                Cross-Pollination Platform for Ideas & Breakthroughs:
              </strong>
            </h1>
            <h3>
              Highlighting research, new concepts, and bringing unique thinkers
              together through programming.
            </h3>
          </li>

          <li>
            <h1>
              <strong>Provoking Events & Networking Forums:</strong>
            </h1>
            <h3>
              Organizing panels, workshops, public talks & more to build a
              greater impact community.
            </h3>
          </li>

          <li>
            <h1>
              <strong>Special Initiatives & Fellowships:</strong>
            </h1>
            <h3>
              Creating opportunities to get involved in exploring some of
              societies most pressing problems.
            </h3>
          </li>

          <li>
            <h1>
              <strong>Critical Community Policy:</strong>
            </h1>
            <h3>
              Outlining common needs through various grassroots voices and lived
              experiences to better inform decision makers.
            </h3>
          </li>
        </ul>
      </HeroContainer>

      <Image
        fluid
        src={Community}
        style={{ paddingTop: "calc(2.5vw + 25px)", width: "100%" }}
      />

      <HeroContainer className="main-banner">
        <Fade bottom>
          <Title className="display-4 fw-bolder">
            Explore how to get involved with INI:
          </Title>
        </Fade>
        <ul className="bulleted-list">
          <li>
            <h1>
              <strong>Partners: “Become a Founding Partner”</strong>
            </h1>
            <h3>
              Bring your resources into the work that we do to help further the
              work that you do.
            </h3>
          </li>

          <li>
            <h1>
              <strong>Community Members:</strong>
            </h1>
            <h3>
              Get involved with our regular monthly engagements or simply
              subscribe to our work.
            </h3>
          </li>

          <li>
            <h1>
              <strong>Supporters:</strong>
            </h1>
            <h3>Help power our work with a unique donation.</h3>
          </li>
        </ul>
      </HeroContainer>

      <HeroContainer className="main-banner text-center">
        <Fade bottom>
          <h1 className="display-4 fw-bolder">
            “Let’s Solve Information Divides{" "}
            <span className="gradient-text">Together.</span>”
          </h1>
          <p className="fs-2 fw-semibold">Contact: ini@vngle.com</p>
        </Fade>
      </HeroContainer>

      <ResponsiveContainer
        fluid
        style={{
          background:
            "linear-gradient(to right, rgba(239, 41, 41), rgba(81, 78, 200))",
          height: "100.964px",
          width: "88%",
          display: "flex",
          borderRadius: "15px",
          justifyContent: "space-between", // Adjust spacing for alignment
          alignItems: "center",
          padding: "0 50px", // Add padding for spacing
          color: "#FFFFFF", // White text color
          fontSize: "18px", // Font size to match
          lineHeight: "1.5", // Adjust line height
        }}
      >
        <div style={{ flex: "1", fontStyle: "italic" }}>
          Sign up and get involved with INI!
        </div>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <a href="https://bit.ly/3X5NG3J" target="_blank">
            <StyledButton>Sign Up</StyledButton>
          </a>
        </Row>
      </ResponsiveContainer>
    </Layout>
  );
};

export default INI;

const TopFeatureContainer = styled(Container)`
  text-align: center;
  margin-bottom: 7rem;

  & > img {
    width: 100%;
    margin-top: -15vw;
  }
`;

const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-weight: 800;
`;

const Subtitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: clamp(1rem, 2vw, 1.5rem);
  line-height: 1.5em;
`;

const Heading = styled.h1`
  font-family: "Inter", sans-serif;
  font-weight: ${(props) => props.weight || "bold"};
  font-size: clamp(1rem, 7vw, ${(props) => props.size || "2.6rem"});
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: ${(props) => props.lineHeight};
  color: ${(props) => props.color};
`;

const SplitSection = styled(Row)`
  margin-top: 1rem;
  margin-bottom: 1rem;

  .content {
    background: ${(props) => props.background};
    padding: 2rem;
    border-radius: 10px;
    height: 100%;

    p.sup {
      font-weight: bold;
      font-size: clamp(1rem, 2.5vw, 1.5rem);
    }
  }

  .react-reveal {
    height: 100%;
  }

  .animated-button {
    color: #000;
    background-color: #f7f7f7;
    border-color: #f7f7f7;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%);
    border-radius: 0.25rem;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  @media (max-width: 47rem) {
    &.section2 {
      flex-direction: column-reverse;
    }
  }

  .image {
    position: relative;
    overflow: hidden;
  }

  .image img {
    width: 100%; /* Ensures image spans full width */
    height: auto; /* Keeps aspect ratio intact */
    object-fit: cover; /* Ensures image covers area without being cut off */
  }

  /* Optional: Adjust the height of the image container */
  .image {
    height: auto; /* Ensures image container height is not constrained */
  }
`;

const MainPageContainer = styled.div`
  // margin-top: 2rem;
  // margin-bottom: 2rem;

  .gatsby-image-wrapper {
    height: 100%;

    img {
      border-radius: 10px;
    }
  }
`;

const ResponsiveContainer = styled(Container)`
  && {
    padding-right: calc(7.5vw + 75px);
    padding-left: calc(7.5vw + 75px);
  }

  @media (max-width: 992px) {
    && {
      padding-right: calc(2.5vw + 25px);
      padding-left: calc(2.5vw + 25px);
    }
  }
`;

const HeroContainer = styled(Container)`
  position: relative;
  margin-top: 2rem;

  .hero-img {
    width: 100%;
    position: absolute;
    z-index: -1;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0.2;
  }

  .w4-container {
    background: #f7f7f7;
    text-align: left;
    padding: 10px 10px;

    .btn {
      font-weight: bold;
      background: #000000;
      color: #fffff9;
      text-align: center;
      cursor: pointer;
    }
  }

  .w-container {
    background: #1a0117;
    color: var(--bs-primary);
    text-align: center;
    padding: 1 em;

    .btn {
      font-weight: bold;
      background: var(--bs-primary);
      color: #1a0117;
      text-align: center;
      cursor: pointer;
    }
  }

  .gradient-text {
    background: linear-gradient(
      to right,
      #07b6e6,
      #dc33b7,
      #fecb3e
    ); /* Gradient colors */
    background-size: 300% 100%; /* Makes the gradient larger than the text */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: bold;

    animation: gradientAnimation 5s ease-in-out infinite; /* Animation applied */
  }

  /* Define the animation */
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%; /* Start at the first color */
    }
    50% {
      background-position: 100% 50%; /* Move through to the last color */
    }
    100% {
      background-position: 0% 50%; /* Loop back to the start */
    }
  }
`;

//prev election style
const ResponsiveHeader = styled.h1`
  && {
    font-size: calc(1vw + 15px);
    color: #212529;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.68px;
  }

  @media (max-width: 992px) {
    && {
      font-size: calc(2vw + 15px);
      margin-top: 25px;
      text-align: center;
    }
  }
`;
const HoverableHeader = styled(ResponsiveHeader)`
  && {
    padding: 50px 50px;
  }
  @media (max-width: 992px) {
    && {
      padding: 50px 25px;
      text-align: center;
    }
  }
  transition: 0.5s ease;
  cursor: default;

  &:hover {
    box-shadow: 1px 1px 0px rgba(239, 181, 181, 0.7),
      2px 2px 0px rgba(219, 161, 161, 0.7), 3px 3px 0px rgba(199, 141, 141, 0.7),
      4px 4px 0px rgba(179, 121, 121, 0.7), 5px 5px 0px rgba(159, 101, 101, 0.7),
      6px 6px 0px rgba(139, 81, 81, 0.7), 7px 7px 0px rgba(119, 61, 61, 0.7),
      8px 8px 0px rgba(99, 41, 41, 0.7), 9px 9px 0px rgba(79, 21, 21, 0.7);
    transform: translateX(-3px);
  }
`;
const ResponsiveImage = styled(Image)`
  && {
    width: calc(25vw + 200px);
    height: calc(25vw + 200px);
  }
  transition: 0.5s ease;
  cursor: default;

  &:hover {
    box-shadow: 1px 1px 0px rgba(185, 206, 229, 0.7),
      2px 2px 0px rgba(165, 186, 209, 0.7), 3px 3px 0px rgba(145, 166, 189, 0.7),
      4px 4px 0px rgba(125, 146, 169, 0.7), 5px 5px 0px rgba(105, 126, 149, 0.7),
      6px 6px 0px rgba(85, 106, 129, 0.7), 7px 7px 0px rgba(65, 86, 109, 0.7),
      8px 8px 0px rgba(45, 66, 89, 0.7), 9px 9px 0px rgba(25, 46, 69, 0.7);
    transform: translateX(-3px);
  }

  @media (max-width: 992px) {
    && {
      width: calc(75vw + 100px);
      height: calc(75vw + 100px);
    }
  }
`;
const StyledRow = styled(Row)`
  display: flex;
  flex-direction: row;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;
const SpecialRow = styled(Row)`
    && {
        padding-top: calc(2.5vw + 150px);
        padding-bottom: calc(15vw + 200px);
    }
    @media (max-width: 992px) {
    && {
        padding-top: calc(2.5vw + 50px);
        padding-bottom: calc(15vw + 50px);
    }
`;
const SpecialCol = styled(Col)`
  justify-content: left;

  @media (max-width: 992px) {
    justify-content: center;
  }
`;
const SpecialCol2 = styled(Col)`
  justify-content: right;

  @media (max-width: 992px) {
    justify-content: center;
  }
`;
const Time = styled.div`
  font-family: "Oleo Script";
`;
const CountdownWrapper = styled.div`
  background: linear-gradient(to right, #ff0000, #0000ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: bold;
  margin-right: 10px;
  font-family: "Inter";
`;
const StyledButton = styled(Button)`
  && {
    padding: 7px 14px;
    color: var(--bs-dark);
    font-weight: bold;
    font-size: 1.25rem;
    transition: color 0.3s;
  }

  &:hover {
    color: var(--bs-dark);
    transition: color 0.3s;
  }
`;

const ResponsiveTitle = styled.h1`
  text-color: white
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }

  @media (min-width: 992px) {
    font-size: 3.5rem;
  }
`;
