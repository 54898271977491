import React from "react";
import styled from "styled-components";
import Layout from "@components/Layouts/MainLayout";
import MediaBG from "@images/services/future-media.jpg";
// import SubForm from "@components/Forms/Sub";

import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Fade } from "react-reveal";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import Logo from "../../images/logo.png";
import Group from "../../images/vngle-juniors/Vngle-jr-group.jpg";
import VngleJrOne from "../../images/vngle-juniors/Vngle-Jr-1.jpg";
import Reporting from "../../images/vngle-juniors/Junior-Reporting.jpg";
import { motion } from "framer-motion";
 import vnglejrvideo from "../../images/vngle-juniors/Vngle-Jr-Header-1.mp4";
 


const vnglejr = () => {
  return (
    <Layout>
      <HeroContainer className="main-banner" style={{ position: 'relative', overflow: 'hidden' }}>
        {/* <motion.div
          animate={{ y: [100, 0], scale: [3.5, 1] }}
          transition={{ duration: 1 }}
        >
          <div className="logo-container">
            <img
              src= {Logo}
              alt="Vngle Logo"
              className="vngle-logo"
            />
          </div>
        </motion.div> */}

        {/* <motion.div
          animate={{ y: [360, 0] }}
          transition={{ duration: 0.7, delay: 0.6 }}
        >
          <div className="center-content">
            <h1 className="main-title">New Media Experiences</h1>
          </div>
        </motion.div>
        <motion.div
          animate={{ opacity: [0, 1] }}
          transition={{ duration: 1.2, delay: 1.1 }}
        >
          <div className="center-content">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSf80FHwD9CVDZK954uUQ_-_0nkMvBGYT1vZ6nHvXsD12H7fDg/viewform" target="_blank" rel="noopener noreferrer">
              <Button className="animated-btn"> 
                Work with Us Today
              </Button>
            </a>
          </div>
        </motion.div> */}
          <video
                   playsinline="playsinline"
                   autoplay="autoplay"
                   muted="muted"
                   loop="loop"
                   className="login-video"
                   style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }}
                 >
                   <source
                     src={vnglejrvideo}
                     type="video/mp4"
                     className="login-vid"
                   />
                   <track kind="captions" />
          </video>

          <Container className="d-flex justify-content-center flex-column" style={{ position: 'relative', zIndex: 1 }}>
          <motion.div
            animate={{ y: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1.3, delay: 1 }}
          >
            <div className="main-title">
              <h1 className="display-4 fw-bolder" style={{color:'white', textAlign:'center', whiteSpace: 'nowrap'}}>
              Vngle Jr
              </h1>
            </div>
            <div>
              <h2 style={{color: 'white', textAlign:'center',}}><strong>Empowering the Next Generation of Voices</strong></h2>
            </div>
          </motion.div>
        </Container>
              
      </HeroContainer>

      
        <HeroContainer className="card-container">
          <div>
            <h1>
              <strong>Vngle Jr. is dedicated to nurturing the next generation of content creators & storytellers.</strong>
            </h1>
            
          </div>
       
        </HeroContainer>
          
          <div>
            <HeroContainer>
              <h1>Vngle Jr. empowers youth with skills, mentorship, and a platform to share their perspectives. We bridge the generational gap for K-12 students to engage in local storytelling that contributes to their local news.</h1>
            <FullImageContainer>
              <img src={Group} alt="" style={{ width: '100%', height: '100%', marginLeft: '0' }} />
            </FullImageContainer>
            </HeroContainer>
          </div>
         
      

      <div className="container px-5">
        <SplitSection className="gx-4 top-container">
          <Col md={6}>
            <Fade left>
              <div className="content p-md-5">
                <Heading className="card-title">
                  1. Community Engagement Programs:
                  <br />
                  <br />
                </Heading>

                <ul>
                  <h2>
                  <li>
                  Connecting youth with leaders and organizational partners to tackle real-world issues.
                  </li>
                  </h2>
                  <br />
                  <h2>
                  <li>
                  Building civic engagement and leadership skills through interactive projects.
                  </li>
                  </h2>
                  <h2>
                  
                  </h2>
                </ul>
              </div>
            </Fade>
          </Col>

          <Col className="image" md={6}>
            <Fade right>
              <StaticImage
                src="../../images/vngle-juniors/communication.jpg"
                alt="Student talking"
                style={{width: '100%',height: '100%', objectFit: 'cover', }}
              />
            </Fade>
          </Col>
        </SplitSection>
      </div>

      <div className="container px-5">
        <SplitSection className="gx-4">
          <Col className="image" md={6}>
            <Fade left>
              <StaticImage
                src="../../images/vngle-juniors/student-group.jpg"
                alt="Group of student"
                style={{width: '100%',height: '500px', objectFit: 'cover', }}
              />
            </Fade>
          </Col>

          <Col md={6}>
            <Fade right>
              <div className="content p-md-5">
                <Heading className="card-title">
                  2. Storytelling Training:
                  <br />
                  <br />
                </Heading>
                <ul>
                <h2>
                  <li>
                  Equipping young people with the skills to report, edit, and share digital stories that matter.
                  </li>
                </h2>
                <br />
                <h2>
                  <li>
                  Students gain hands-on mentorship from experienced media professionals.
                  </li>
                  </h2>
                </ul>
              </div>
            </Fade>
          </Col>
        </SplitSection>
      </div>

      <div className="container px-5">
        <SplitSection className="gx-4">
          <Col md={6}>
            <Fade left>
              <div className="content p-md-5">
                <Heading className="card-title">
                  3. Youth-Created Media:
                  <br />
                  <br />
                </Heading>
                <ul>
                  
                <h2>
                  <li>
                Showcasing the voices of tomorrow through youth-led reporting and social media content.
                </li>
                </h2>
                
                  <h2>
                  <li>
                  Vngle provides opportunities for young people to amplify their unique perspectives across the media landscape.
                  </li>
                  </h2>
                  
                </ul>
              </div>
            </Fade>
          </Col>

          <Col className="image" md={6}>
            <Fade right>
              <StaticImage
                src="../../images/vngle-juniors/Vngle-Jr-Recording.png"
                alt="Kid Recording"
                style={{width: '100%',height: '500px', objectFit: 'cover', }}
              />
            </Fade>
          </Col>
        </SplitSection>
      </div>

      <HeroContainer className="main-banner">

        
          <div className="center-content">
            <h1>
            Our workshops help K-12 students grow in understanding how to take part in the creator economy, identify and deter misinformation, and how to leverage new tools & technology in storytelling.
            </h1>
          </div>
          <FullImageContainer>
    <img src={VngleJrOne} alt="" />
        </FullImageContainer>
      </HeroContainer>

      <div style={{marginTop:'0'}}>
        <HeroContainer >
          <div >
            <h1>The Value Partners Receive Through Vngle Jr:</h1>
            <ul>
              <li>
              <h2>
                Empowering local impact by investing in youth media skills training to better represent themselves and their communities.
              </h2>
            </li>

            <li>
              <h2>
                Amplify the stories of underrepresented voices through fresh new perspectives.
              </h2>
            </li>

            <li>
              <h2>
              Cultivate a pipeline of skilled, passionate storytellers for current and future media employers.
              </h2>
            </li>
            </ul>
          <FullImageContainer>
            <img src={Reporting} alt="" style={{ width: "100%",height: "90%", zIndex: "0" }}/>
         </FullImageContainer>
          </div>
        </HeroContainer>
      </div>

      
      <Fade bottom>
        <HeroContainer className="card-container">
        <div className="text-card">
        <h1>
          Success Story: Vngle Jr Partner Workshop with the{" "}
          <a href="https://www.asteamvillage.org/" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
            aSTEAM Village
          </a>{" "}
          community in Kansas City, Missouri:
        </h1>
      </div>
        </HeroContainer>
      </Fade>

      <SwiperContainer>
        <Swiper
          modules={[Navigation, EffectFade, Autoplay, Pagination]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          navigation
          effect={"fade"}
          speed={800}
          slidesPerView={1}
          pagination={true}
          loop
          className="myswiper"
        >
          <SwiperSlide className="swiperslide">
            <StaticImage src="../../images/services/past-event-x.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide className="swiperslide">
            <StaticImage src="../../images/services/past-event-y.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide className="swiperslide">
            <StaticImage src="../../images/services/past-event-z.jpg" alt="" />
          </SwiperSlide>
        </Swiper>
      </SwiperContainer>

      <HeroContainer className="main-banner">
        <Fade bottom>
          <div className="logo-container">
            <img
              src={Logo}
              alt="Vngle Logo"
              className="vngle-logo"
            />
          </div>
        </Fade>

        <Fade bottom>
          <div className="center-content">
            <h1 className="ender">
            Let’s build the future of media
            with your organization.
            </h1>
          </div>
        </Fade>

        <img src={MediaBG} alt="" className="hero-img" />
      </HeroContainer>

      <HeroContainer>
  <div
    style={{
      display: 'flex',
      flexDirection: 'row', // Arrange children side by side
      alignItems: 'center', // Align items vertically in the center
      justifyContent: 'center', // Center the content horizontally
      gap: '1rem', // Adds space between the text and the button
      marginTop:'2rem'
    }}
  >
    <h1
      style={{
        fontFamily: '"Playfair Display", serif',
        fontSize: '2rem',
        fontWeight: 'bold',
        color: '#000',
        margin: 0, // Removes default margin
      }}
    > 
       <strong>Connect with us today </strong>
      
    </h1>

      {/* */}
    <a
      href="https://forms.gle/1G2TP6DgYY7kvzc16"
      target="_blank"
      style={{
        textDecoration: 'none', // Removes underline from link
      }}
    >
      <button
        className="btn btn-primary"
        style={{marginRight: '2rem'}}
      >
        Contact Us 
      </button>
    </a>
  </div>
</HeroContainer>


      {/* <SubForm /> */}
    </Layout>
  );
};

const SwiperContainer = styled.div`
  .myswiper {
    width: 90%;
    border-radius: 30px;
    margin: 50px auto;
    height: 40rem;
    opacity: 0.9;

    .swiperslide {
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .swiper-pagination-bullet-active {
    background-color: white !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: white !important;
  }

  // h1 {
  //   text-align: center;
  //   font-family: Inter, sans-serif;
  //   color: white;
  // }

  @media (max-width: 768px) {
  .myswiper {
    height: 20rem; // Smaller height for mobile devices
  }
}
`;

const Heading = styled.h1`
  font-family: "Inter", sans-serif;
  font-weight: ${(props) => props.weight || "bold"};
  font-size: clamp(1rem, 7vw, ${(props) => props.size || "2.6rem"});
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: ${(props) => props.lineHeight};
  color: ${(props) => props.color};

  &.card-title {
    font-family: "Playfair Display", serif;
    text-align: center;
  }
`;

const HeroContainer = styled(Container)`
  position: relative;
  width: 100%;

  &.main-banner {
    min-height: 44rem;
    padding: 8rem;
  }
    .banner{
    min-height: 44rem;
    padding: 2rem;
    }

  h1 {
    text-align: center;
    font-family: Inter, sans-serif;
  }

  .logo-container {
    max-width: 10rem;
    margin: 0 auto;
    padding-top: 10px;
  }

  .vngle-logo {
    object-fit: cover;
    max-width: 10rem;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
  }

  .logo-title {
    font-size: 30px;
  }

  .center-content {
    padding-top: 10px;
    justify-content: center;
    display: flex;
  }

  .ender {
    font-size: clamp(2rem, 5vw, 3.5rem); /* Minimum 2rem, scales with 5vw, max 3.5rem */
}
    .main-title{
     width: 100%; /* Ensure full width on mobile */
    text-align: left !important; /* Make sure it's aligned left on mobile */
    color:white;
    }

  .animated-btn {
    color: white;
    padding: 15px 30px;
    border-radius: 30px;
    font-weight: bold;
  }

  .hero-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0.5;
  }

  &.card-container {
    padding: 0rem 2rem;
    max-width: 70rem;
  }

  .row {
    background-color: #f5f5f7;
  }

  .text-card {
    background-color: #ffffff;
    margin: 20px 10px;
    padding: 40px;
    border-radius: 30px;

    .sub-title {
      color: #0cc3dc;
    }
  }

  .card-title {
    justify-content: center;
    display: flex;
    padding-bottom: 10px;
  }

  &.two-container {
    position: flex;
    padding: 30px;
  }

  .line-style {
    text-decoration: underline;
  }

  .login-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    object-fit: cover;
  }
  
`;


const SplitSection = styled(Row)`
  margin-top: 2rem;
  margin-bottom: 2rem;

  h2 {
    font-family: "Inter", sans-serif;
    text-align: center;
    margin-bottom: 0em;
  }

  .text-container {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 30px;
  }

  .content {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 30px;
    height: 100%;
    transition: transform 1s;
  }

  .gatsby-image-wrapper {
    img {
      border-radius: 30px;
    }
  }
`;

const FullImageContainer = styled.div`
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);

  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    display: block;

    @media (max-width: 768px) {
      height: auto; /* Maintain aspect ratio on smaller screens */
    }
  }
`;

export default vnglejr;
