import React from "react";
import styled from "styled-components";
import Layout from "@components/Layouts/MainLayout";
//import Background from "@images/services/connectivity-background.png";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Col, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import { motion } from "framer-motion";
//import SubForm from "@components/Forms/Sub";
import connectivity_vid from "../../images/services/Vngle_connectivity.mp4";

const connectivity = () => {
  return (
    <Layout>
      <HeroContainer className="main-banner" style={{ position: 'relative', overflow: 'hidden' }}>
  <video
    playsinline="playsinline"
    autoplay="autoplay"
    muted="muted"
    loop="loop"
    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }}
  >
    <source
      src={connectivity_vid}
      type="video/mp4"
      className="login-vid"
    />
    <track kind="captions" />
  </video>
  
  <Container className="d-flex justify-content-center flex-column" style={{ position: 'relative', zIndex: 1 }}>
    <motion.div
      animate={{ y: [100, 0], opacity: [0, 1] }}
      transition={{ duration: 1.3, delay: 1 }}
    >
      <div className="main-title">
        <h1 className="display-4 fw-bolder" style={{color:'white', textAlign:'left'}}>
          Strategic Connectivity
        </h1>
      </div>
      <div>
        <h2 className="sub-title" style={{color:'white', textAlign:'left'}}>
          Helping Civic-Focused Organizations Build Trust,
          Serve Communities, and Drive Greater Impact
        </h2>
      </div>
    </motion.div>
  </Container>
</HeroContainer>

      <MaContainer>
        <div className="mt display-3">
          <h1>
            <span>
            <strong>Connect Authentically, Serve Effectively.</strong>
            </span>{" "}
            <br /><br />
            Vngle helps you craft strategies to grow greater awareness, build campaigns, market research & more. 
            <br /><br />
            We <u>empower</u> teams to forge <u>deeper</u>,<u>meaningful</u> <u>connections</u> with target communities & source authentic content.
          </h1>
        </div>

        <SplitSection background="" className="section2 gy-3">
          <Col md={6}>
            <Fade left>
              <div className="content p-md-5">
                <p className="sup display-2">
                  <Heading>
                  Our approach relies on trained local experts—individuals who live and breathe their communities—delivering hyper-local insights and stories.
                  </Heading>
                </p>
              </div>
            </Fade>
          </Col>
          <Col className="image" md={6}>
            <Fade right>
              <StaticImage
                src="../../images/services/sic.png"
                alt="Hand holding mics"
              />
            </Fade>
          </Col>
        </SplitSection>
      </MaContainer>

      <div className="bg-dark">
      <HContainer>
        <p  className="font-serif">How It Works</p>
        <ol>
          <li>
            <p> 1. Understand Community Needs</p>
            <p className="font-serif">
              We start by identifying communication gaps, learning about your
              target audience’s challenges and goals.
            </p>
          </li>
          <li>
            <p>2. Build Custom Engagement Strategies</p>
            <p className="font-serif">
              Using hyper-local insights, we craft tailored media and outreach plans
              that align with your mission.
            </p>
          </li>
          <li>
            <p>3. Amplify Voices & Long-Term Impact</p>
            <p className="font-serif">
              Our network of trained grassroots experts gather verified stories and
              real-time insights, creating content with deep local context &
              helping you adapt to evolving community needs.
            </p>
          </li>
        </ol>
      </HContainer>
    </div>
      <MaContainer>
            <div className="content-wrapper img">
              <div className="hero-img"></div>
              <StaticImage
                src="../../images/services/interview.png"
                style={{ width: '100%', height: '100%' }}
              />
            </div>
      </MaContainer>
      <div>
      {/*Gain text font*/}
      <HeroContainer>
        <div>
          <h1 className= "display-4 fw-bolder">Why Choose Vngle?</h1>
        </div>
        <div style={{marginTop: "2rem"}}>
          <ul>
          <h2>Our service isn’t just a tool—it’s a partnership to enhance your ability to connect, serve, and create lasting change. By leveraging Vngle’s grassroots network, you’ll:</h2>
             <h3><li>Build trust by amplifying authentic, community-sourced voices.</li></h3>
            <h3><li>Expand your reach with credible, hyper-local stories.
            Gain insights to inform better decisions and strategies.</li></h3>
            <h3><li>Ensure initiatives are tailored to meet the most pressing needs of your target areas.</li></h3>
          </ul>
        </div>
      </HeroContainer>
      </div>
      <MaContainer>

        <SplitSection background="" className="section2 gy-3">
          <Col md={{ span: 6, order: "last" }}>
            <Fade left>
              <div className="content p-md-5">
                <p className="sup">
                  <Heading>
                    {" "}
                    Local Expertise: Insights sourced from individuals who intimately understand their communities, not outsiders.{" "}
                  </Heading>
                </p>
              </div>
            </Fade>
          </Col>
          <Col className="image" md={6}>
            <Fade right>
              <StaticImage
                src="../../images/services/town_shot.jpg"
                alt="Town Shot"
                style={{  width: '100%', height: '500px', objectFit: 'cover' }}
              />
            </Fade>
          </Col>
        </SplitSection>

        <SplitSection background="" className="section2 gy-3">
          <Col md={6}>
            <Fade left>
              <div className="content p-md-5">
                <p className="sup">
                  <Heading>
                    {" "}
                    Verified Storytelling: Real, authentic narratives that build trust and strengthen engagement.{" "}
                  </Heading>
                </p>
              </div>
            </Fade>
          </Col>
          <Col className="image" md={6}>
            <Fade right>
            <StaticImage
                src="../../images/services/newsWire/recording.jpg"
                alt="Man interviewing"
                style={{  width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Fade>
          </Col>
        </SplitSection>

        <SplitSection background="" className="section2 gy-3">
          <Col md={{ span: 6, order: "last" }}>
            <Fade left>
              <div className="content p-md-5">
                <p className="sup">
                  <Heading>
                    {" "}
                    Customizable Plans: Scalable outreach strategies tailored to your specific goals and community focus.{" "}
                  </Heading>
                </p>
              </div>
            </Fade>
          </Col>
          <Col className="image" md={6}>
            <Fade right>
              <StaticImage
                src="../../images/services/k.jpg"
                alt="Camera in hand"
              />
            </Fade>
          </Col>
        </SplitSection>
      </MaContainer>

      
      <LayeredContainer>    
     <StaticImage
      src="../../images/services/stand.png"
      alt="Man standing"
      className="background-img"
    /> 

    <div className="text-content">
      Take the Next Step in Transforming your Connectivity
    </div>
  </LayeredContainer>
     
    {/* <div style={{marginTop: '35rem'}}>
     <HeroContainer>
      <h1>
      Who’s Strategic Connectivity For?
      <br />
      <strong>Organizations seeking to engage diverse, hard-to-reach communities. For Example:</strong>
      </h1>

      <h1 style={{textAlign:'left', marginTop:'2rem'}}>Non-Profits:
        <br/>
        Expand your advocacy with stories that truly resonate and mobilize communities.
      </h1>
      
      <h1 style={{textAlign:'left', marginTop:'2rem'}}>Local Governments:
        <br/>
        Enhance public engagement with localized insights and trustworthy communication strategies.
      </h1>

      <h1 style={{textAlign:'left', marginTop:'2rem'}}>
      Civic Entities & Universities:
      <br/>
      Drive sustainable collaboration with impact strategies rooted in grassroots intelligence.
      </h1>
     </HeroContainer>
    </div> */}


    <MaContainer>
  <div className="container">
    <h1
      style={{
        fontFamily: '"Playfair Display", serif',
        fontSize: '2rem',
        fontWeight: 'bold',
        color: '#000',
        margin: 0, // Removes default margin
      }}
    >
      <strong>
      Our Strategic Connectivity Service is customized to fit your needs, helping amplify your impact today.
      </strong>
    </h1>

    <a
      href="https://forms.gle/1G2TP6DgYY7kvzc16"
      target="_blank"
      style={{
        textDecoration: 'none', // Removes underline from link
      }}
    >
      <button
        className="btn btn-primary"
        style={{marginRight: '2rem'}}
      >
        Contact Us 
      </button>
    </a>
  </div>
</MaContainer>

      {/* <SubForm /> */}

      <Container className="mt-3">
        <small>
        Disclaimer: The Strategic Connectivity service operates 
        independently from Vngle’s newsroom. The Strategic Connectivity service is 
        designed to help partners connect with diverse communities and craft meaningful
        initiatives without influencing Vngle's internal editorial processes.  While custom projects under this service allow for collaborative input from clients, 
        this collaboration does not influence the editorial discretion of Vngle’s independent reporting. 
        </small>
      </Container>
    </Layout>
  );
};

const HeroContainer = styled.div`
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;

  h1 {
    font-family: "Playfair Display", serif;
    margin-bottom: 0rem;
    text-align: center;
  }

  .main-title {
    max-width: 50rem;
    display: flex;
    color: black;
  }

  .pic-wrapper {
    margin-top: 50%;
  }

  .sub-title {
    color: black;
  }

  .btn-container {
    padding: 2rem;
  }

  .animated-btn {
    color: white;
    padding: 15px 30px;
    border-radius: 30px;
    font-weight: bold;
  }

  .hero-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;

    h1 {
      font-size: clamp(2rem, 5vw, 3rem);
    }

    .main-title {
      max-width: 100%;
    }

    .btn-container {
      padding: 1rem;
    }
  }
`;

const Heading = styled.h1`
  font-family: "Inter", sans-serif;
  font-weight: ${(props) => props.weight || "bold"};
  font-size: clamp(3rem, 7vw, ${(props) => props.size || "2.6rem"});
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: ${(props) => props.lineHeight};
  color: ${(props) => props.color};
`;

const MaContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: center;

  .mt {
    margin-bottom: 4rem;

    .r {
      color: #e612d0;
    }
  }

  .gatsby-image-wrapper {
    height: 100%;

    img {
      border-radius: 10px;
    }
  }

  h1 {
    font-weight: bold;
    font-family: "Playfair Display", serif;
    text-align: center;
    font-size: 3rem;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: clamp(2rem, 5vw, 3rem);
    }
  }

  .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 1;
  margin-top: 80vh;
}

@media (max-width: 768px) {
  .container {
    margin-top: 40vh;
  }
}
`;

const HContainer = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 1rem;

  p {
    color: white;
    font-weight: bold;
    font-size: clamp(1.5rem, 4vw, 3rem); /* Responsive font size */
    text-align: left;

    .b {
      color: #000000;
      text-align: center;
    }

    .f {
      color: var(--bs-primary);
    }
  }

  .one, .two, .third {
    color: #1ee4f0;
    text-align: center;
    font-size: clamp(1.5rem, 3vw, 2rem); /* Scales font for animations */
    animation: flip 3s infinite;
    animation-delay: calc(0.3s * var(--i));
    animation-iteration-count: 5;
  }

  .four {
    color: black;
    font-weight: bold;
    font-size: clamp(1.5rem, 4vw, 3rem); /* Responsive font size */
    text-align: left;
  }

  @keyframes flip {
    0%, 80% {
      transform: rotateY(360deg);
    }
  }

  @media (max-width: 768px) {
    /* Adjustments for smaller screens */
    p {
      text-align: center;
      font-size: clamp(1.2rem, 3vw, 2rem);
    }

    .one, .two, .third {
      font-size: clamp(1.2rem, 3vw, 1.5rem);
    }

    .four {
      text-align: center;
      font-size: clamp(1.2rem, 3vw, 2rem);
    }
  }
`;

const SplitSection = styled(Row)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .content {
    background: ${(props) => props.background};
    padding: 2rem;
    border-radius: 10px;
    height: 100%;
    transition: transform 1s;
  }

  @media (max-width: 768px) {
    .content {
      padding: 1rem;
    }

    &.section2 {
      flex-direction: column-reverse;
    }
  }
`;

const LayeredContainer = styled.div`
  position: relative; /* Establish positioning context */
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;

 .background-img {
    position: absolute; /* Background image fills this container */
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
    z-index: 0; /* Behind the text */
    object-fit: cover; /* Ensure proper scaling */

    @media (max-width: 768px) {
      height: 50vh; /* Reduce height for smaller screens */
    }
  }

  .text-content {
    position: relative; /* Text appears above the background */
    z-index: 1; /* Ensure text is above the background */
    color: white;
    font-family: "Playfair Display", serif;
    font-weight: bold;
    font-size: clamp(1.5rem, 4vw, 3rem);
    padding: 2rem;
  }

  @media (max-width: 768px) {
    .text-content {
      font-size: clamp(1.2rem, 3vw, 2rem);
    }
  }
`;

export default connectivity;